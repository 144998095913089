<template>
	<el-dialog
		v-model="visible"
		width="750px"
		class="container"
		:title="title"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<yi-form
				:model="model"
				:rules="rules"
				ref="formRef"
				label-width="120px"
				size="small"
				:form-list="formList"
			>
				<template #licensePlateNum>
					<el-select-v2
						placeholder="请选择车牌号"
						:options="licensePlateOptions"
						filterable
						size="default"
						:disabled="disabled"
						v-model="model.licensePlateNum"
						@change="onNumChange"
						style="width: 190px"
					>
						<template #default="{ item }">
							<div class="custom-option">
								<span>{{ item.label }}</span>
								<span>{{ item.statusName }}</span>
							</div>
						</template>
					</el-select-v2>
				</template>
			</yi-form>
			<div style="margin-left: 20px">
				<p style="font-weight: 600; font-size: 12px">交车描述</p>
				<el-input
					maxLength="200"
					type="textarea"
					show-word-limit
					v-model="model.deliveryDesc"
					style="margin-bottom: 20px; width: 600px"
					:disabled="disabled"
					:autosize="{ minRows: 4 }"
				/>
				<p class="title">验车照片</p>
				<el-form :model="model" :rules="rules" ref="uploadRef">
					<el-form-item prop="appearancePicList">
						<div class="upload-title">
							<span class="upload-text">*</span>
							<span>
								外观（{{ model.appearancePicList ? model.appearancePicList.length : 0 }}/25）
							</span>
						</div>
						<yi-file-uploader
							v-model="model.appearancePicList"
							productType="renrenjia"
							sourceType="image"
							:max="25"
							:uploaderSize="20"
							:disabled="disabled"
							accept=".jpg,.jpeg,.png"
							:allImages="allImages.appearancePicList"
						/>
					</el-form-item>
					<el-form-item prop="interiorPicList">
						<div class="upload-title">
							<span class="upload-text">*</span>
							<span>内饰（{{ model.interiorPicList ? model.interiorPicList.length : 0 }}/25）</span>
						</div>
						<yi-file-uploader
							v-model="model.interiorPicList"
							productType="renrenjia"
							sourceType="image"
							:max="25"
							:uploaderSize="20"
							:disabled="disabled"
							accept=".jpg,.jpeg,.png"
							:allImages="allImages.interiorPicList"
						/>
					</el-form-item>
					<el-form-item prop="enginePicList">
						<div class="upload-title">
							<span class="upload-text">*</span>
							<span>机舱底盘（{{ model.enginePicList ? model.enginePicList.length : 0 }}/25）</span>
						</div>
						<yi-file-uploader
							v-model="model.enginePicList"
							productType="renrenjia"
							sourceType="image"
							:max="25"
							:uploaderSize="20"
							:disabled="disabled"
							accept=".jpg,.jpeg,.png"
							:allImages="allImages.enginePicList"
						/>
					</el-form-item>
					<el-form-item prop="deliveryPicList">
						<div class="upload-title">
							<span>其它（{{ model.deliveryPicList ? model.deliveryPicList.length : 0 }}/25）</span>
						</div>
						<yi-file-uploader
							v-model="model.deliveryPicList"
							productType="renrenjia"
							sourceType="image"
							:max="25"
							:uploaderSize="20"
							:disabled="disabled"
							accept=".jpg,.jpeg,.png"
							:allImages="allImages.deliveryPicList"
						/>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<template #footer>
			<div class="dialog-footer" v-if="!disabled">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { defineExpose, ref, inject, defineEmits } from 'vue'
import { useState, useModal, useFetch } from '@/utils/hooks.js'
import { getStore } from '@/utils/store'
import { form } from './config'
import { status } from '../enums'
import {
	createCarStockOut,
	carSelectList,
	vehicleDetail,
	editCarStockOut,
	carStockOutDetail,
} from '@/api/vehicle'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const formList = ref(form)
const { visible, title, setVisible, setTitle } = useModal()
const [model, setModel] = useState({})
const [allImages, setAllImages] = useState({})
const [licensePlateOptions, setOptions] = useState([])
const [disabled, setDisabled] = useState(false)
const { isLoading, fetchData } = useFetch()
const rules = {
	carMileage: [{ pattern: /^[0-9]*$/, message: '车辆里程只能是整数' }],
	appearancePicList: [{ required: true, message: '请上传外观照片' }],
	interiorPicList: [{ required: true, message: '请上传内饰照片' }],
	enginePicList: [{ required: true, message: '请上传机舱底盘照片' }],
}
const formRef = ref(null)
const uploadRef = ref(null)
const userInfo = getStore({ name: 'userInfo' })

async function onNumChange(val) {
	const deliveryResults = formList.value.find(item => item.prop === 'deliveryResults')
	const { err, data } = await fetchData(vehicleDetail, val)
	if (err) $message.error(err)
	const { id, ...rest } = data
	const { status } = rest
	const list = [1, 2, 4]
	const delivery = [5]
	const onlyEsc = list.includes(status)
	const onlyDeli = delivery.includes(status)
	if (deliveryResults.options.length === 1) {
		model.value.deliveryResults = ''
	}
	deliveryResults.options = onlyEsc
		? [{ label: '退出运营', value: 2 }]
		: onlyDeli
		? [{ label: '交车出库', value: 1 }]
		: [
				{ label: '交车出库', value: 1 },
				{ label: '退出运营', value: 2 },
		  ]
	if (onlyEsc) model.value.deliveryResults = 2
	if (onlyDeli) model.value.deliveryResults = 1
	setModel(Object.assign(model.value, rest, { carId: id }))
}

function open(id, type = 'create') {
	fetchList(type)
	const time = formList.value.find(item => item.prop === 'deliveryTime')
	time.hide = type === 'detail'
	const carMileage = formList.value.find(item => item.prop === 'carMileage')
	const deliveryResults = formList.value.find(item => item.prop === 'deliveryResults')
	carMileage.attrs.disabled = type === 'detail'
	deliveryResults.attrs.disabled = !!id
	if (id) {
		fetchDetail(id)
		if (type === 'detail') {
			setTitle('交车出库单详情')
			setDisabled(true)
			setVisible(true)
			return
		}
		setTitle('编辑交车出库单')
	} else {
		setTitle('新增交车出库单')
	}

	if (type === 'exit') {
		setTitle('交车出库退出运营')
		model.value.deliveryResults = 2
		deliveryResults.options = [{ label: '退出运营', value: 2 }]
	} else if (type === 'create') {
		model.value.deliveryResults = 1
		deliveryResults.options = [{ label: '交车出库', value: 1 }]
	} else {
		deliveryResults.options = [
			{ label: '交车出库', value: 1 },
			{ label: '退出运营', value: 2 },
		]
	}
	const num = formList.value.find(item => item.prop === 'licensePlateNum')
	num.attrs.disabled = !!id
	setVisible(true)
}

function onClose() {
	setVisible(false)
	setModel({})
	setDisabled(false)
}
async function fetchDetail(id) {
	const { err, data } = await fetchData(carStockOutDetail, id)
	if (err) return $message.error(err.message)
	console.log('detail', data)
	const { deliveryPicList, enginePicList, interiorPicList, appearancePicList } = data
	const images = {
		deliveryPicList: (deliveryPicList || [])
			.concat(interiorPicList)
			.concat(appearancePicList)
			.concat(enginePicList),
		enginePicList: (enginePicList || [])
			.concat(deliveryPicList)
			.concat(interiorPicList)
			.concat(appearancePicList),
		interiorPicList: (interiorPicList || [])
			.concat(enginePicList)
			.concat(deliveryPicList)
			.concat(appearancePicList),
		appearancePicList: (appearancePicList || [])
			.concat(interiorPicList)
			.concat(enginePicList)
			.concat(deliveryPicList),
	}
	setAllImages(images)
	setModel(data)
}
async function onSubmit() {
	await Promise.all([formRef.value.validate(), uploadRef.value.validate()])
	const params = {
		...model.value,
		createUser: userInfo.userId,
	}
	const service = title.value.includes('编辑') ? editCarStockOut : createCarStockOut
	const { err } = await fetchData(service, params)
	if (err) return $message.error(err.message)
	$message.success('操作成功')
	emit('on-refresh')
	onClose()
}
function mapStatus(value) {
	const target = status.find(item => item.code == value)
	return target ? target.name : '--'
}
async function fetchList(type) {
	const statusList = {
		exit: [1, 2, 4],
		create: [5],
		edit: [5],
		detail: [1, 2, 4, 5],
	}[type]
	const { err, data } = await fetchData(carSelectList, { statusList: [1, 2, 3, 4, 5, 6, 7, 8] })
	if (err) return $message.error(err.message)
	const options = data.map(item => ({
		...item,
		label: item.licensePlateNum,
		value: item.id,
		disabled: !statusList.includes(item.status),
		statusName: mapStatus(item.status),
	}))
	const valid = []
	const invalid = []
	for (const option of options) {
		if (statusList.includes(option.status)) valid.push(option)
		else invalid.push(option)
	}
	setOptions(valid.concat(invalid))
	const { createUserName } = model.value
	setModel({ ...model.value, createUserName: createUserName || userInfo.userName })
}

defineExpose({ open })
</script>
<style lang="less">
.el-select-v2__placeholder {
	font-weight: 400 !important;
}
.el-select-dropdown__option-item {
	font-size: 14px !important;
}
</style>
<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.custom-option {
	.flexible(row, center, space-between);
}
.upload-title {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.upload-text {
	color: #f56c6c;
	margin-right: 4px;
	line-height: 24px;
}
</style>
